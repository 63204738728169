import { useContext, useEffect, useState } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import SessionContext from '../../context/SessionContext';
import HeaderInfo from './HeaderInfo/HeaderInfo';
import OrderDetails from './OrderDetails/OrderDetails';
import Address from './Address/Address';
import Quote from './Quote/Quote';
import OrderSummary from './OrderSummary/OrderSummary';
import styles from './Account.module.scss';
import * as utils from '../../utils/OrderUtils';
import Payment from './Payment/Payment';
import PaymentContext from '../../context/PaymentContext';
import '../../translations/i18n';
import TicketDownload from './TicketDownload/TicketDownload';
import TravelDocuments from './TravelDocuments/TravelDocuments';
import TicketsToPost from './TicketsToPost/TicketsToPost';
import AlsoInterested from './AlsoInterested/AlsoInterested';
import PageView from '../Analytics/PageView/PageView';
import { getAppConfig } from '../../config/config';
import useFetchOrder from '../../hooks/useFetchOrder';

function Account({ isLoggedIn }) {
  const navigate = useNavigate();
  const config = getAppConfig();

  const loginUrl = utils.prependLangUrlPath('/customer/login', config.brand, config.language_prefix);

  useEffect(() => {
    if (!isLoggedIn && !sessionStorage.orderId) {
      if (config.brand === 'DSD') {
        window.location.reload();
      } else {
        navigate(loginUrl);
      }
    }
  }, [isLoggedIn]);

  const { t } = useTranslation('common');

  const sessionCtx = useContext(SessionContext);

  const { session } = sessionCtx;

  const orderId = session.loginOrder;
  const sessionId = session.sid;

  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');

  const [cookies, removeCookie] = useCookies('FailedPaymentAttempts');
  const numberOfFailedAttempts = cookies.FailedPaymentAttempts ? parseInt(cookies.FailedPaymentAttempts, 10) : 0;
  const [isFailedPayment, setIsFailedPayment] = useState(false);

  const paymentCtx = useContext(PaymentContext);
  const {
    paymentStatus, forceFetch, orderUpdate, payAmount,
  } = paymentCtx;

  const { isLoading, serverError, order } = useFetchOrder(orderId, sessionId, orderUpdate, paymentStatus, forceFetch);

  // If CCNS redirect to login page
  if (order.status === 'customer_contacted_no_sale') {
    const navigationUrl = utils.prependLangUrlPath('/customer/login', config.brand, config.language_prefix);
    navigate(navigationUrl, { state: { id: 1, orderStatus: order.status } });
  }

  const location = useLocation();

  useEffect(() => {
    if ((numberOfFailedAttempts >= 3) || (order.status === 'payment_failed' && numberOfFailedAttempts >= 1)) {
      setIsFailedPayment(true);
    }
    if (paymentStatus) {
      if (paymentStatus === 'payment-success') {
        setSuccessMessage(t('payment.success_msg'));
        setFailureMessage('');
      } else if (paymentStatus === 'payment-failed') {
        setFailureMessage(t('payment.failure_msg'));
        setSuccessMessage('');
      }
    }
    if (location.search.indexOf('success') !== -1) {
      setSuccessMessage(t('payment.success_msg'));
    } else if (location.search.indexOf('fail') !== -1) {
      setFailureMessage(t('payment.failure_msg'));
    }
  }, [forceFetch, order]);

  const billingAddress = {
    fname: order.billing_address_fname,
    lname: order.billing_address_lname,
    company: order.billing_address_company,
    street1: order.billing_address_street1,
    street2: order.billing_address_street2,
    city: order.billing_address_city,
    region: order.billing_address_region,
    postal_code: order.billing_address_postal_code,
    country: order.billing_address_country,
    phone: order.billing_address_phone,
    mobile: order.billing_address_mobile,
  };

  const deliveryAddress = {
    fname: order.delivery_address_fname,
    lname: order.delivery_address_lname,
    company: order.delivery_address_company,
    street1: order.delivery_address_street1,
    street2: order.delivery_address_street2,
    city: order.delivery_address_city,
    region: order.delivery_address_region,
    postal_code: order.delivery_address_postal_code,
    country: order.delivery_address_country,
    phone: order.delivery_address_phone,
    mobile: order.delivery_address_mobile,
  };

  const orderTickets = utils.getOrderTickets(order);

  const [quote, setQuote] = useState(false);
  const [ticketsToPost, setTicketsToPost] = useState(false);
  const [ticketsToDownload, setTicketsToDownload] = useState(false);
  const [travelDocuments, setTravelDocuments] = useState(false);
  const [hotelVouchers, setHotelVouchers] = useState([]);
  const vouchers = utils.getVouchers(order, 'all');
  const orderTicketsPost = utils.getOrderTickets(order, 'post');
  const [isPayable, setIsPayable] = useState(true);
  const [isQuote, setIsQuote] = useState(false);
  const [isParisPackage, setIsParisPackage] = useState(false);
  const [isFlaggedPayment, setIsFlaggedPayment] = useState(false);
  const [isMissingAttributes, setIsMissingAttributes] = useState(false);
  const [isPaymentFailedTimeLimit, setIsPaymentFailedTimeLimit] = useState(false);

  useEffect(() => {
    const fetchHotelVouchers = async () => {
      const response = await utils.getHotelVouchers(order);
      if (response && (response[0].hotelDetails.length !== 0 || response[0].tickets.length !== 0)) {
        setHotelVouchers(response);
        setTravelDocuments(true);
      }
    };
    fetchHotelVouchers();
    if (config.brand === 'DSD' && orderTickets && orderTickets.length > 0 && order.status === 'quote') {
      setQuote(true);
      setTicketsToDownload(false);
      setTicketsToPost(false);
    }
    if (config.brand !== 'DSD' && orderTickets && orderTickets.length > 0 && order.status === 'quote' && order.payments_accepted_code !== 'expired') {
      setQuote(true);
      setTicketsToDownload(false);
      setTicketsToPost(false);
    }
    if (orderTicketsPost && orderTicketsPost.length > 0) {
      setTicketsToPost(true);
    }
  }, [order]);

  useEffect(() => {
    if (vouchers && vouchers.length > 0) {
      // For DSD quotes don't show tickets to download
      if (config.brand === 'DSD' && quote) {
        setTicketsToDownload(false);
      } else {
        setTicketsToDownload(true);
      }
    }
    const ticketAttributes = orderTickets && orderTickets.map((ticket) => ticket.attributes.map((attribute) => Object.values(attribute)).flat()).flat();
    if (orderTickets && ticketAttributes.includes('Required') && order.status === 'quote') {
      setIsMissingAttributes(true);
    }
  }, [orderTickets]);

  const transportTickets = utils.getTransportTickets(order);

  // Check if order should be payable
  useEffect(() => {
    const payDisabledStatus = ['customer_contacted_no_sale', 'canceled'];
    if (payDisabledStatus.includes(order.status)) {
      setIsPayable(false);
    } else if (order.status === 'quote' || order.status === 'payment_failed') {
      order.order_tickets.every((ticket) => {
        // Check if quote is for hotel, 1253 = accommodation tag
        if (ticket.product_categories[1253]) {
          setIsQuote(true);
          return false;
        }
        return true;
      });
      // Check if quote is older than 7 days, 604800000 miliseconds = 7 days
      if ((new Date() - new Date(order.created)) > 604800000) {
        setIsQuote(true);
      }
    } else if (order.comments_admin && order.comments_admin.some((comment) => comment.message.includes('Online Failed Payment Limit Reached'))) {
      if (order.status !== 'payment_failed' && order.status !== 'action_required') {
        removeCookie('FailedPaymentAttempts', { path: '/' });
        setIsFailedPayment(false);
      } else if (order.status === 'action_required') {
        if (order.order_actions.some((action) => action.type.includes('failed_payment_limit'))) {
          order.order_actions.forEach((action) => {
            if (action.type === 'check_payment' && action.complete === 0 && !isFailedPayment) {
              setIsFlaggedPayment(true);
            } else if (action.type === 'failed_payment_limit' && action.complete === 1 && isFailedPayment) {
              removeCookie('FailedPaymentAttempts', { path: '/' });
              setIsFailedPayment(false);
            }
          });
        } else {
          removeCookie('FailedPaymentAttempts', { path: '/' });
          setIsFailedPayment(false);
        }
      }
    } else if (order.order_actions && order.order_actions.length !== 0) {
      order.order_actions.forEach((action) => {
        if (action.type === 'failed_payment_limit' && action.complete === 0 && !isFailedPayment) {
          setIsFlaggedPayment(true);
        }
      });
    } else {
      setIsPayable(true);
      setIsQuote(false);
      setIsFlaggedPayment(false);
    }

    // Check if order is Paris package deal
    if (order.order_tickets) {
      order.order_tickets.forEach((ticket) => {
        if (ticket.booking && ticket.booking.length > 0 && ticket.booking[0].room_package_type && ticket.destinations[0]) {
          if (ticket.booking[0].room_package_type === 'ROOM_TICKET_GEN' && ticket.destinations[0].destination_id === '24053') {
            setIsParisPackage(true);
          }
        }
        // Les Village Nature is always a 2 day ticket.
        if (ticket.attractions[0] && hotelVouchers[0]) {
          if (ticket.attractions[0].attraction_id === '242389') {
            hotelVouchers[0].hotelDetails.ticketDaysDuration = 2;
          }
        }
      });
    }

    // Check if order status if payment failed and it has been more than 4 hours (1.44e+7 milliseconds is 4 hours)
    if (order.status === 'payment_failed' && (new Date() - new Date(order.created)) > 1.44e+7) {
      setIsPaymentFailedTimeLimit(true);
    }
  }, [order, hotelVouchers]);

  return (
    <div className={styles.account}>
      <PageView userEmail={order.email} orderId={order.id} />
      <Helmet>
        <script src="https://cdn.checkout.com/js/framesv2.min.js" />
      </Helmet>
      {serverError && <p className={styles.error}>Error in fetching data ...</p>}
      {isLoading ? (
        <div className={`${styles.loading} loading`} />
      ) : (
        <>
          <HeaderInfo orderId={order.id} address={billingAddress} status={order.customer_order_status} departureDate={order.departure_date} paid={order.balance <= 0} internalStatus={order.status} order={order} />
          {successMessage && <p className={styles.success}>{successMessage}</p>}
          {failureMessage && !isFailedPayment && <p className={styles.error}>{failureMessage}</p>}
          {isFailedPayment && <p className={styles.error}>{t('There was a problem processing your payment. Please contact us.')}</p>}
          {order.customer_order_status === 'awaiting_tickets' && (
            <div className={styles.delay}>
              <p>{t('Thank you for booking with us. As soon as your theme park tickets for next year have been released by the supplier (expected to be mid-December) we will email you to let you know.')}</p>
            </div>
          )}
          <div className={styles.summaryChunks}>
            <OrderDetails created={order.created} total={order.total} payments={order.order_payments} currency={order.currency} discounts={order.order_discounts} tickets={order.order_tickets} charges={order.order_charges} />

            {/* Billing address hidden, to unhide comment in line below */}
            {/* { (deliveryAddress.street1 !== '') ? <Address billing={billingAddress} delivery={deliveryAddress} /> : <Address billing={billingAddress} /> } */}
          </div>
          <Tabs>
            <TabList className={`${styles.tabList} ${styles.mainTabList}`}>
              {quote && order.status === 'quote' && <Tab className={styles.tab}>{t('payment.quote_tab')}</Tab>}
              {config.brand !== 'DSD' && order.balance > 0 && isPayable && <Tab className={styles.tab}>{t('payment.payment_tab')}</Tab>}
              {order.status === 'payment_failed' && <Tab className={styles.tab}>{t('payment.order_summary_tab')}</Tab>}
              {travelDocuments && <Tab className={styles.tab}>{t('payment.travel_documents_tab')}</Tab>}
              {ticketsToDownload && <Tab className={styles.tab}>{t('payment.tickets_download_tab')}</Tab>}
              {ticketsToPost && <Tab className={styles.tab}>{t('payment.tickets_post_tab')}</Tab>}
            </TabList>
            {quote && order.status === 'quote' && (
              <TabPanel className={styles.tabPanel}>
                <Quote tickets={orderTickets} />
              </TabPanel>
            )}
            {config.brand !== 'DSD' && order.balance > 0 && isPayable && (
            <TabPanel className={styles.tabPanel}>
              {isQuote && (
              <div className={styles.quote}>
                <p>{(config.brand === 'UO' || config.brand === 'SW') ? t(`To confirm or discuss your quote, please contact the team on ${config.phone_number}.`) : t('If you would like to secure your booking please contact us.')}</p>
              </div>
              )}
              {isFlaggedPayment && (
              <div className={styles.quote}>
                <p><em>{t('Your payment is Pending.')}</em></p>
                <br />
                <p><em>{t('Our operations team will attempt to confirm and authorise the payment within 24 hours. Once this is complete you will receive your order confirmation email. We will be in touch if we require any further information.')}</em></p>
              </div>
              )}
              {isFailedPayment && (
              <div className={styles.quote}>
                <p><em><b>{t('Your payment has failed.')}</b></em></p>
                <br />
                <p>
                  <em>
                    {t('Sorry, but you have reached the maximum number of attempts. One of the team will get in touch within 48 hours to try and assist. If you are traveling soon and need help right away, please ')}
                    <a href={config.brand === 'AA' ? '/contactus.php' : `/${config.language_prefix}/contact-us`}>{t('contact us.')}</a>
                  </em>
                </p>
              </div>
              )}
              {isPaymentFailedTimeLimit && !isQuote && (
              <div className={styles.quote}>
                <p>
                  {(config.brand === 'UO' || config.brand === 'SW') ? t(`Sorry, but due to our live pricing and availability something may have changed. , Please call us on ${config.phone_number} to confirm your order.`) : t('Sorry, but due to our live pricing and availability some things may have changed. To confirm your order, please ')}
                  <a href={config.brand === 'AA' ? '/contactus.php' : `/${config.language_prefix}/contact-us`}>{t('contact us.')}</a>
                </p>
              </div>
              )}
              {isMissingAttributes && !isQuote && (
              <div className={styles.quote}>
                <p><b>{t('Please call us to confirm your Quote.')}</b></p>
                <br />
                <p>
                  <em>
                    {t('We need to gather some details from you before your tickets can be booked, so please')}
                    {' '}
                    {(config.brand === 'UO' || config.brand === 'SW') ? t(`contact the team on ${config.phone_number}`) : <a href={config.brand === 'AA' ? '/contactus.php' : `/${config.language_prefix}/contact-us`}>{t('contact us')}</a>}
                    {t(' to confirm your order.')}
                  </em>
                </p>
              </div>
              )}
              {isPayable && !isQuote && !isFlaggedPayment && !isFailedPayment && !isMissingAttributes && !isPaymentFailedTimeLimit && (
                <Payment order={order} failureMessage={failureMessage} setFailureMessage={setFailureMessage} payAmount={payAmount} />
              )}
            </TabPanel>
            )}
            {order.status === 'payment_failed' && (
            <TabPanel className={styles.tabPanel}>
              <OrderSummary tickets={orderTickets} />
            </TabPanel>
            )}
            {travelDocuments && (
              <TabPanel className={styles.tabPanel}>
                <TravelDocuments hotelVouchers={hotelVouchers} transport={transportTickets} orderStatus={order.status} isParisPackage={isParisPackage} orderId={order.id} />
              </TabPanel>
            )}
            {ticketsToDownload && (
              <TabPanel className={styles.tabPanel}>
                <TicketDownload vouchers={vouchers} orderId={order.id} />
              </TabPanel>
            )}
            {ticketsToPost && (
              <TabPanel className={styles.tabPanel}>
                <TicketsToPost tickets={orderTicketsPost} />
              </TabPanel>
            )}
          </Tabs>
          {/* <AlsoInterested /> */}
        </>
      )}
    </div>
  );
}

export default Account;
