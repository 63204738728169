function Download(destination, productType, orderId, productName) {
  const event = {
    event: 'ca_download',
    product_download: {
      order_id: orderId,
      destination,
      product_type: productType,
      product_name: productName,
    },
  };

  window.dataLayer.push(event);
}

export default Download;
