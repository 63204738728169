import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import '../../../translations/i18n';
import parse from 'html-react-parser';
import { getCurrencyCode, formatPrice } from '../../../utils/OrderUtils';
import styles from './OrderDetails.module.scss';

function OrderDetails(props) {
  const {
    created, currency, total, payments, discounts, tickets, charges,
  } = props;
  const { t } = useTranslation('common');

  const itemDate = new Date(created);
  itemDate.toLocaleString();
  const itemDay = itemDate.getDate();
  const itemMonth = t(itemDate.toLocaleString('default', { month: 'short' }));
  const itemYear = itemDate.getFullYear();
  const chargesTotal = charges ? charges.reduce((a, v) => a + (Number(v.price)), Number(0)) : 0;
  let subtotal = discounts ? discounts.reduce((a, v) => a + (Number(v.amount)), Number(total - chargesTotal)) : (total - chargesTotal);
  const totalPaid = payments ? payments.reduce((a, v) => a + (Number(v.amount)), Number(0)) : 0;

  const amendments = tickets.filter((ticket) => {
    if (ticket.type_ticket === 'product_amendment') {
      return true;
    }
    return false;
  });

  subtotal = amendments && amendments.reduce((a, v) => a - (Number(v.price)), Number(subtotal));

  const [isMobile, setIsMobile] = useState(Number(window.innerWidth) <= 768);
  const [mobileDetailsView, setMobileDetailsView] = useState(false);

  function handleWindowSizeChange() {
    setIsMobile(Number(window.innerWidth) <= 768);
    setMobileDetailsView(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleMobileViewUpdate = () => {
    setMobileDetailsView(!mobileDetailsView);
  };

  return (
    <div className={styles.summaryChunk}>
      <h3 className={styles.title}>
        {t('account.order_details.title')}
        {isMobile && <button className={styles.mobileView} type="submit" onClick={handleMobileViewUpdate}>{mobileDetailsView ? '⌃' : '>'}</button>}
      </h3>
      {(!isMobile || mobileDetailsView) && (
      <ul className={styles.orderList}>
        <li className={styles.orderLi}>
          <strong>
            {t('account.order_details.created')}
            {' '}
          </strong>
          {created && <span>{`${itemDay} ${itemMonth} ${itemYear}`}</span>}
        </li>
        <li className={styles.orderLi}>
          <strong>
            {t('account.order_details.total')}
            {' '}
          </strong>
          <span>
            {parse(getCurrencyCode(currency))}
            {formatPrice(subtotal)}
          </span>
        </li>
        {amendments && amendments.map((amendment) => (
          <li className={styles.orderLi} key={amendment.order_ticket_id}>
            <strong>
              {amendment.title}
              {' '}
            </strong>
            <span>
              {parse(getCurrencyCode(currency))}
              {formatPrice(Number(amendment.price))}
            </span>
          </li>
        ))}
        {discounts && discounts.map((discount) => (
          <li className={styles.orderLi} key={discount.discount_id}>
            <strong>
              {`${t('account.order_details.discount')}`}
              {discount.code && ` (${discount.code})`}
              {' '}
            </strong>
            <span>
              {parse(getCurrencyCode(currency))}
              -
              {formatPrice(discount.amount)}
            </span>
          </li>
        ))}
        {charges && charges.map((charge) => (
          charge.price !== '0.00' && (
          <li className={styles.orderLi} key={charge.order_charge_id}>
            <strong>
              {charge.title}
              {' '}
            </strong>
            <span>
              {parse(getCurrencyCode(currency))}
              {formatPrice(charge.price)}
            </span>
          </li>
          )
        ))}
        <li className={styles.orderLi}>
          <strong>
            {t('account.order_details.order_total')}
            {' '}
          </strong>
          <span>
            {parse(getCurrencyCode(currency))}
            {formatPrice(total)}
          </span>
        </li>
        <li className={styles.orderLi}>
          <strong>
            {t('account.order_details.paid')}
            {' '}
          </strong>
          <span>
            {parse(getCurrencyCode(currency))}
            {formatPrice(totalPaid)}
          </span>
        </li>
        <li className={`${styles.orderLi} ${styles.orderRemainingLi}`}>
          <strong>
            {t('account.order_details.balance')}
            {' '}
          </strong>
          <span>
            {parse(getCurrencyCode(currency))}
            {formatPrice(total - totalPaid)}
          </span>
        </li>
      </ul>
      )}
    </div>
  );
}

export default OrderDetails;
