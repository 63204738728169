import { useState, useEffect, useCallback } from 'react';
import API from './phx';
import * as utils from '../utils/OrderUtils';

const getTicketValidityText = (ticketTitle, date) => {
  const year = date.includes('/') ? date.split('/')[2] : date.split('-')[2];

  const [ticketValidityText, setTicketValidityText] = useState('');

  let fetchUrl = '';

  if (ticketTitle && ticketTitle.includes('Disney')) {
    fetchUrl = utils.appendGeoParam('/text?types=ticket_validity_tooltip_disney');
  } else if (ticketTitle.includes('SeaWorld')) {
    fetchUrl = utils.appendGeoParam('/text?types=ticket_validity_tooltip_seaworld');
  } else if (ticketTitle.includes('Universal')) {
    fetchUrl = utils.appendGeoParam(`/text?types=ticket_validity_tooltip_universal_${year}`);
  }

  const fetchData = useCallback(async () => {
    const resp = await API.get(fetchUrl);
    const data = await resp?.data;
    if (ticketTitle && ticketTitle.includes('SeaWorld')) {
      let seaworldTicketValidity = data.data[0].body.split('December');
      seaworldTicketValidity = `${seaworldTicketValidity[0]}December ${year}.${seaworldTicketValidity[1]}`;
      setTicketValidityText(seaworldTicketValidity);
    } else {
      setTicketValidityText(data.data[0].body);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return ticketValidityText;
};

export default getTicketValidityText;
