import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import Tickets from '../Tickets/Tickets';
import ComboTickets from '../ComboTickets/ComboTickets';
import styles from './OrderSummary.module.scss';

function OrderSummary(props) {
  const { tickets, tabID } = props;
  const { t } = useTranslation('common');

  const nonComboVouchers = tickets && tickets.filter((ticket) => !ticket.combo);
  const comboTickets = [];
  tickets.filter((ticket) => ticket.combo).forEach((ticket) => {
    if (comboTickets.length === 0) {
      comboTickets.push([ticket]);
    } else {
      comboTickets.forEach((vouch) => {
        if (vouch[0].title === ticket.title) {
          vouch.push(ticket);
        } else {
          comboTickets.push([ticket]);
        }
      });
    }
  });

  const nonComboOrderTickets = nonComboVouchers && nonComboVouchers.map((ticket) => (
    <Tickets
      key={ticket.order_ticket_id}
      id={ticket.order_ticket_id}
      title={ticket.title}
      img={ticket.img}
      date={ticket.date}
      checkout_date={ticket.checkout_date}
      time={ticket.time}
      tickets={ticket.ticket_numbers}
      price={ticket.price}
      currency={ticket.currency}
      attributes={ticket.attributes}
      quantity={ticket.quantity}
      subtitle={ticket.subtitle}
    />
  ));

  const comboOrderTickets = comboTickets && comboTickets.map((ticket) => (
    <ComboTickets
      key={ticket[0].product_id}
      id={ticket[0].product_id}
      title={ticket[0].title}
      img={ticket[0].img}
      tickets={ticket}
      currency={ticket[0].currency}
      orderStatus={ticket[0].order_status}
      isQuote
    />
  ));

  return (
    <div id={tabID} className={styles.quote}>
      <h2 className={styles.title}>{t('payment.quote_tab')}</h2>
      <ul className={styles.comboTicketsList}>{comboOrderTickets}</ul>
      <ul className={styles.ticketsList}>{nonComboOrderTickets}</ul>
    </div>
  );
}

export default OrderSummary;
