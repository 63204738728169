import React, {
  useReducer, useState, useMemo, createContext, useEffect, useRef,
} from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { useTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Tooltip } from 'react-tooltip';
import parse from 'html-react-parser';
import AddressLookup from 'address-lookup';
import { getCurrencyCode, formatPrice } from '../../../../utils/OrderUtils';
import useCountries from '../../../../hooks/useCountries';
import BankPayment from '../BankPayment/BankPayment';
import GiftCardPayment from '../GiftCardPayment/GiftCardPayment';
import Giropay from '../GiroPay/GiroPay';
import styles from './PaymentDetails.module.scss';
import * as utils from '../../../../utils/OrderUtils';
import IdealPayment from '../IdealPayment/IdealPayment';
import Paypal from '../Paypal/Paypal';
import ApplePayTab from '../ApplePay/ApplePayTab';
import { getSiteConfig, getAppConfig } from '../../../../config/config';

function PaymentDetails(props) {
  const { t } = useTranslation('common');

  const {
    order, failureMessage, setFailureMessage, payAmount,
  } = props;

  const config = getAppConfig();

  const cardholderName = order && utils.formatName(order.billing_address_fname, order.billing_address_lname);

  // current order to store new address if updated.
  const [currentOrder, setCurrentOrder] = useState(order);
  const [amount, setAmount] = useState(order && (payAmount || order.balance));
  const [name, setName] = useState(cardholderName);
  const [street1, setStreet1] = useState(order && order.billing_address_street1);
  const [street2, setStreet2] = useState(order && order.billing_address_street2);
  const [city, setCity] = useState(order && order.billing_address_city);
  const [region, setRegion] = useState(order && order.billing_address_region);
  const [country, setCountry] = useState(order && order.billing_address_country);
  const [countryShortname, setCountryShortname] = useState('');
  const [postcode, setPostcode] = useState(order && order.billing_address_postal_code);
  const [phone, setPhone] = useState(order && order.billing_address_phone);
  // used for checking billing address form validation before form has loaded
  const [isBillingAddressValid, setIsBillingAddressValid] = useState(!!(order && name && country && street1 && city && postcode));

  const address = order && {
    company: order.billing_address_company,
    street1,
    street2,
    city,
    region,
    postcode,
    country,
  };

  const billingAddress = address && Object.values(address).filter(Boolean).join(', ');

  const countriesList = useCountries();

  const [editState, setEditState] = useState(false);
  // checks if the form has been loaded before in the current page load
  // if not then it will reuse billing details and send it.
  // Because form is not loaded until the edit button is pressed.
  const [hasEditFormLoadedBefore, setHasEditFormLoadedBefore] = useState(false);
  const [giroPay, setGiroPay] = useState(false);
  const [showIdealPay, setShowIdealPay] = useState(false);
  const [showPayPal, setShowPayPal] = useState(false);
  const [showApplePay, setShowApplePay] = useState(false);
  const [showGiftTab, setShowGiftTab] = useState(!(config.brand === 'UO' || config.brand === 'SW'));
  const [showStoreCard, setShowStoreCard] = useState(false);
  const [validAmount, setValidAmount] = useState(true);
  const [depositWeeks, setDepositWeeks] = useState();

  const lang = utils.getLang();

  const addressLookupAutocompleteRef = useRef(null);
  const addressLookupRef = useRef(null);
  const street1InputRef = useRef(null);

  useEffect(() => {
    const siteConfig = getSiteConfig();
    if (siteConfig.giroPayEnabledLangs.includes(lang)) {
      setGiroPay(true);
    }
    if (siteConfig.idealPayEnabledLangs.includes(lang) && order.billing_address_country === siteConfig.idealPayEnabledCountry) {
      setShowIdealPay(true);
    }
    // eslint-disable-next-line no-undef
    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
      setShowApplePay(true);
    }
    // PayPal payments removed from customer accounts from business
    // if (siteConfig.paypalEnableLangs.includes(lang)) {
    //   setShowPayPal(true);
    // }
  }, []);

  const handleAddressUpdate = () => {
    setHasEditFormLoadedBefore(true);
    setEditState(!editState);
    // when edit form is closed clear ref
    if (editState) {
      addressLookupRef.current = null;
    }
  };

  useEffect(() => {
    setCurrentOrder({
      ...currentOrder, // Copy the old fields
      billing_address_street1: street1,
      billing_address_street2: street2,
      billing_address_city: city,
      billing_address_region: region,
      billing_address_country: country,
      billing_address_postal_code: postcode,
      billing_address_phone: phone,
    });
    setCountryShortname(countryShortname);
    if (!addressLookupRef.current && street1InputRef.current) {
      addressLookupRef.current = new AddressLookup(street1InputRef.current);
      const reactRoot = document.getElementById('cust-account');
      const countryEl = reactRoot.querySelector('[name="country"]');
      const countrySelectedOption = countryEl.options[countryEl.selectedIndex];

      // set initial country
      if (countrySelectedOption) {
        addressLookupRef.current.autoCompleteOptions.componentRestrictions = { country: countrySelectedOption.getAttribute('data-country-shortname') };
      }

      // create an instance of autocomplete api
      addressLookupAutocompleteRef.current = addressLookupRef.current.init();
    }
  }, [street1,
    street2,
    city,
    region,
    country,
    postcode,
    phone,
    countryShortname,
    editState]);

  const addressLookup = addressLookupRef.current;
  const addressLookupAutocomplete = addressLookupAutocompleteRef.current;
  if (addressLookupAutocomplete) {
    if (countryShortname) {
      addressLookupAutocomplete.setOptions({ componentRestrictions: { country: countryShortname } });
    }

    addressLookupAutocomplete.addListener('place_changed', () => {
      // when selecting an address from suggested list this is what it will do

      //  update the form values
      setValue('street1', addressLookup.getFullAddress().street1);
      setValue('street2', addressLookup.getFullAddress().street2);
      setValue('city', addressLookup.getFullAddress().city);
      setValue('region', addressLookup.getFullAddress().region);
      setValue('postcode', addressLookup.getFullAddress().postcode);
      setValue('country', addressLookup.getFullAddress().country);

      // update state
      setStreet1(addressLookup.getFullAddress().street1);
      setStreet2(addressLookup.getFullAddress().street2);
      setCity(addressLookup.getFullAddress().city);
      setRegion(addressLookup.getFullAddress().region);
      setPostcode(addressLookup.getFullAddress().postcode);
      setCountry(addressLookup.getFullAddress().country);

      // trigger validation after selecting an address
      trigger();
    });
  }

  const {
    setValue, trigger, register, handleSubmit, formState: { errors, isValid },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const { ref, ...rest } = register('street1', {
    required: true,
    onChange: (e) => {
      setStreet1(e.target.value);
      setValue('street1', e.target.value);
    },
  });
  const onSubmit = (data) => {
  };

  const deposit = order.deposit;
  const [depAvailable, setDepAvailable] = useState(true);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depMsg, setDepMsg] = useState(true);
  const [fullPayment, setFullPayment] = useState(false);
  const [fullAmtMsg, setFullAmtMsg] = useState(false);
  const [insideFullAmtMsg, setInsideFullAmtMsg] = useState(false);

  useEffect(() => {
    if (deposit && Object.keys(deposit).length > 0) {
      const depositDays = 42;
      const depositWeek = (depositDays % 365) / 7;
      setDepositWeeks(depositWeek);
      if (deposit.deposit_enabled && (+deposit.qualify) > 0) {
        const depositAmt = +deposit.deposittotal;
        setDepositAmount(depositAmt);
        const cutoffDate = new Date(Date.parse(order.departure_date));
        cutoffDate.setDate(cutoffDate.getDate() - parseInt(depositDays, 10));

        const depStatus = ['quote', 'payment_failed'];
        if (depStatus.includes(order.status)) {
          if (cutoffDate < new Date()) {
            setFullPayment(true);
            setDepAvailable(false);
            setDepositAmount(amount);
            setInsideFullAmtMsg(true);
            setDepMsg(false);
          } else {
            setDepAvailable(true);
            setDepMsg(true);
          }
        } else {
          setDepositAmount(1);
          setDepAvailable(false);
          if (cutoffDate < new Date()) {
            setFullPayment(true);
            setDepositAmount(amount);
            setDepMsg(false);
            setInsideFullAmtMsg(true);
          }
        }
      } else {
        setDepositAmount(amount);
        setFullPayment(true);
        setDepMsg(false);
        setFullAmtMsg(true);
      }
    }
  }, [deposit]);

  // *** For future reference ***
  // if we save this form to database then consider the county field on the admin page is select field.
  return (
    <>
      <Helmet>
        <script async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyADgxi3zb3O0_9CGeYLrYIfCLjg4csoAOw&libraries=places" />
      </Helmet>
      <div className={styles.paymentChunk}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={styles.fieldset}>
            <div className={styles.field}>
              <label className={styles.label} htmlFor="amount">
                {t('payment.payment_form.amount')}
                {' '}
                <span title="Required" className={styles.required}> *</span>
                {(!errors.amount) && (!validAmount) && (
                <span className={styles.error}>
                  {t('payment.depost_msg')}
                  {' '}
                  {parse(getCurrencyCode(order.currency))}
                  {formatPrice(depositAmount)}
                  {' '}
                  {t('payment.depost_msg1')}
                  {' '}
                  {parse(getCurrencyCode(order.currency))}
                  {formatPrice(order.balance)}
                  {' '}
                </span>
                )}
              </label>
              <input
                className={styles.input}
                type="number"
                defaultValue={amount}
                min={depositAmount}
                max={order && order.balance}
                {...register('amount', {
                  onChange: (e) => {
                    const { min, max, value } = e.target;

                    if (Number(value) >= Number(min) && Number(value) <= Number(max)) {
                      setAmount(value);
                      setValidAmount(true);
                      if (!validAmount) {
                        setValidAmount(true);
                      }
                    } else {
                      setValidAmount(false);
                      if (validAmount) {
                        setValidAmount(false);
                      }
                    }
                    setAmount(value);
                    // eslint-disable-next-line radix
                    if (parseInt(e.target.value) < parseInt(order.balance)) {
                      setShowStoreCard(true);
                    } else {
                      setShowStoreCard(false);
                    }
                  },
                  required: true,
                })}
                readOnly={fullPayment}
              />
              {(errors.amount || !amount) && <p className={styles.error}>{t('payment.payment_form.amount_err')}</p>}
            </div>
            { ((depAvailable || fullPayment) && (insideFullAmtMsg || fullAmtMsg || depMsg)) && (
              <>
                <p>
                  {t('payment.deposit_calculation')}
                  { insideFullAmtMsg && <span className={styles.tooltipTitle} data-tooltip-id="inside-full-amount" data-tooltip-html={`${t('payment.full_amount_inside')} ${depositWeeks} ${t('payment.full_amount_inside1')}`} data-tooltip-place="bottom"> i </span>}
                  { fullAmtMsg && <span className={styles.tooltipTitle} data-tooltip-id="full-amount" data-tooltip-html={t('payment.full_amount')} data-tooltip-place="bottom"> i </span>}
                  { depMsg && <span className={styles.tooltipTitle} data-tooltip-id="deposit-amount" data-tooltip-html={t('payment.deposit_msg')} data-tooltip-place="bottom"> i </span>}
                </p>
                { insideFullAmtMsg && <Tooltip id="inside-full-amount" className={styles.tooltipBody} />}
                { fullAmtMsg && <Tooltip id="full-amount" className={styles.tooltipBody} />}
                { depMsg && <Tooltip id="deposit-amount" className={styles.tooltipBody} />}
              </>
            )}
          </fieldset>
          <fieldset className={styles.fieldset}>
            <div className={styles.field}>
              <h3 className={styles.addressLabel}>{t('account.billing_address')}</h3>
              <p className={`${styles.address} gb-mask`}>
                {billingAddress}
                {' '}
                <button className={styles.editLink} type="submit" onClick={handleAddressUpdate}>Edit</button>
              </p>
              {!editState && (hasEditFormLoadedBefore ? !isValid : !isBillingAddressValid) && (
                <p className={styles.error}>{t('payment.payment_form.err')}</p>
              )}
            </div>
            { editState && (
            <div className="customer-accounts-address gb-mask">
              <div className={styles.field}>
                <label className={styles.label} htmlFor="name">
                  {t('payment.payment_form.name')}
                  {' '}
                  <span title="Required" className={styles.required}>*</span>
                </label>
                <input
                  className={styles.input}
                  type="text"
                  defaultValue={name}
                  {...register('name', {
                    onChange: (e) => {
                      setName(e.target.value);
                    },
                    required: true,
                  })}
                />
                {errors.name && <p className={styles.error}>{t('payment.payment_form.name_err')}</p>}
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="country">
                  {t('payment.payment_form.country')}
                  {' '}
                  <span title="Required" className={styles.required}>*</span>
                </label>
                <select
                  className={styles.select}
                  value={country}
                  name="country"
                  {...register('country', {
                    onChange: (e) => {
                      setCountry(e.target.value);
                      const cShortname = e.target.options[e.target.selectedIndex].getAttribute('data-country-shortname');
                      setCountryShortname(cShortname);
                    },
                    required: true,
                  })}
                >
                  {
                    countriesList.countries.map((countries) => <option data-country-shortname={countries.shortname} key={countries.id}>{countries.title}</option>)
                  }
                </select>
                {errors.country && <p className={styles.error}>{t('payment.payment_form.country_err')}</p>}
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="street">
                  {t('payment.payment_form.street')}
                  {' '}
                  <span title="Required" className={styles.required}>*</span>
                </label>
                <input
                  {...rest}
                  ref={(e) => {
                    ref(e);
                    street1InputRef.current = e;
                  }}
                  name="street1"
                  id="street1"
                  className={`${styles.input} ${styles.inputFirst}`}
                  type="text"
                  defaultValue={street1}
                />
                <input
                  className={styles.input}
                  type="text"
                  onChange={(e) => setStreet2(e.target.value)}
                  defaultValue={street2}
                  {...register('street2', {
                    onChange: (e) => {
                      setStreet2(e.target.value);
                    },
                  })}
                />
                {errors.street1 && <p className={styles.error}>{t('payment.payment_form.street_err')}</p>}
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="city">
                  {t('payment.payment_form.city')}
                  {' '}
                  <span title="Required" className={styles.required}>*</span>
                </label>
                <input
                  className={styles.input}
                  type="text"
                  defaultValue={city}
                  {...register('city', {
                    onChange: (e) => {
                      setCity(e.target.value);
                    },
                    required: true,
                  })}
                />
                {errors.city && <p className={styles.error}>{t('payment.payment_form.city_err')}</p>}
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="region">
                  {t('payment.payment_form.county')}
                  {' '}
                </label>
                <input
                  className={styles.input}
                  type="text"
                  onChange={(e) => setRegion(e.target.value)}
                  defaultValue={region}
                  {...register('region', {
                    onChange: (e) => {
                      setRegion(e.target.value);
                    },
                  })}
                />
                {errors.region && <p className={styles.error}>{t('payment.payment_form.county_err')}</p>}
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="postcode">
                  {t('payment.payment_form.postcode')}
                  {' '}
                  <span title="Required" className={styles.required}>*</span>
                </label>
                <input
                  className={styles.input}
                  type="text"
                  defaultValue={postcode}
                  {...register('postcode', {
                    onChange: (e) => {
                      setPostcode(e.target.value);
                    },
                    required: true,
                  })}
                />
                {errors.postcode && <p className={styles.error}>{t('payment.payment_form.postcode_err')}</p>}
              </div>
              <div className={styles.field}>
                <label className={styles.label} htmlFor="phone">
                  {t('payment.payment_form.phone')}
                  {' '}
                </label>
                <span className={`${styles.inputWrap} validd errorr`}>
                  <input
                    className={styles.input}
                    type="text"
                    defaultValue={phone}
                    {...register('phone', {
                      onChange: (e) => {
                        setPhone(e.target.value);
                      },
                    })}
                  />
                </span>
                {errors.phone && <p className={styles.error}>{t('payment.payment_form.phone_err')}</p>}
              </div>
            </div>
            )}
          </fieldset>
        </form>
      </div>
      <div className={styles.paymentChunk}>
        <Tabs>
          <TabList className={`${styles.tabList} ${styles.paymentTabList}`}>
            <Tab className={styles.tab}>
              {utils.isATBrand()
                && (
                <>
                  <svg width="10px">
                    <use xlinkHref="#tab-card" />
                  </svg>
                  {' '}
                </>
                )}
              {t('payment.payment_form.cardtab')}
            </Tab>
            { showApplePay && (
            <Tab className={styles.tab}>
              <svg width="10px">
                <use xlinkHref="#apple" />
              </svg>
              {' '}
              {t('payment.payment_form.applepay')}
            </Tab>
            )}
            { showPayPal && (
            <Tab className={styles.tab}>{t('payment.payment_form.paypal')}</Tab>
            )}
            { showGiftTab && (
            <Tab className={styles.tab}>
                {utils.isATBrand()
                && (
                  <>
                    <svg width="10px">
                      <use xlinkHref="#tab-gift" />
                    </svg>
                    {' '}
                  </>
                )}
              {t('payment.payment_form.giftcardtab')}
            </Tab>
            )}
            { giroPay && (
            <Tab className={styles.tab}>{t('payment.payment_form.giropaytab')}</Tab>
            )}
            { showIdealPay && (
            <Tab className={styles.tab}>{t('payment.payment_form.ideal')}</Tab>
            )}
          </TabList>
          <TabPanel className={styles.tabPanel}>
            <BankPayment order={currentOrder} payAmount={amount} cardholderName={name} isBillingDetailsValid={hasEditFormLoadedBefore ? isValid : isBillingAddressValid} isShowStoreCard={showStoreCard} isValidAmount={validAmount} />
          </TabPanel>
          { showApplePay && (
          <TabPanel>
            <ApplePayTab order={order} payAmount={amount} />
          </TabPanel>
          )}
          { showPayPal && (
          <TabPanel className={styles.tabPanel}>
            <Paypal order={currentOrder} payAmount={amount} />
          </TabPanel>
          )}
          { showGiftTab && (
          <TabPanel className={styles.tabPanel}>
            <GiftCardPayment order={currentOrder} />
          </TabPanel>
          )}
          { giroPay && (
          <TabPanel className={styles.tabPanel}>
            <Giropay order={currentOrder} payAmount={amount} />
          </TabPanel>
          )}
          { showIdealPay && (
          <TabPanel>
            <IdealPayment order={currentOrder} payAmount={amount} failureMessage={failureMessage} setFailureMessage={setFailureMessage} />
          </TabPanel>
          )}
        </Tabs>
      </div>
    </>
  );
}

export default PaymentDetails;
