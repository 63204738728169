function Login(email, orderId, userId, sid, username, source) {
  const event = {
    event: 'login',
    user: {
      email,
      id: sid,
      login_order_id: orderId,
      user_id: userId,
      username,
      source,
    },
  };

  window.dataLayer.push(event);
}

export default Login;
