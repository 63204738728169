import axios from 'axios';
import * as utils from '../../../../utils/OrderUtils';
import API from '../../../../api/phx';
import Payments from '../../../Analytics/Payments/Payments';

// eslint-disable-next-line import/prefer-default-export
export function applePay(orderContext, payAmount, paymentData) {
  const loading = (state = true) => {
    if (state) {
      // Create the parent div
      const parentDiv = document.createElement('div');
      parentDiv.className = 'loading_overlay'; // Assign a class to the parent div

      // Create the child div
      const childDiv = document.createElement('div');
      childDiv.className = 'loading_circle'; // Assign a class to the child div

      // Append the child div to the parent div
      parentDiv.appendChild(childDiv);

      // Append the parent div to the document body (you can replace document.body with another element if needed)
      document.body.appendChild(parentDiv);
    } else if (!state) {
      const parentDiv = document.querySelector('.loading_overlay'); // Replace with the actual selector if needed
      // Remove the parentDiv from the document
      parentDiv.remove();
    }
  };

  const config = {
    currencyCode: orderContext.currency,
    countryCode: getCountryCode(),
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['amex', 'masterCard', 'maestro', 'visa'],
    total: {
      label: domainLabel().applePayLabel,
      amount: payAmount,
      type: 'final',
    },
  };

  const domainConfig = utils.getLang();

  const BACKEND_URL_VALIDATE_SESSION = `https://${window.location.hostname}/api/payments/apple-pay-validate-session?cached=0&geo=${domainConfig}`;
  const BACKEND_URL_PAY = `https://${window.location.hostname}/api/payments/checkoutdotcom/apple-pay?cached=0&account=1&geo=${domainConfig}`;

  const validateApplePaySession = (appleUrl, callback) => {
    axios
      .post(
        BACKEND_URL_VALIDATE_SESSION,
        {
          appleUrl,
        },
        {
          headers: { 'Access-Control-Allow-Origin': '*' },
        },
      )
      .then((response) => {
        callback(response.data);
      });
  };

  const performTransaction = (details, callback) => {
    // eslint-disable-next-line no-param-reassign
    orderContext.amountToPay = (payAmount * 100).toFixed();
    // eslint-disable-next-line no-param-reassign
    orderContext.order_id = orderContext.id;
    // eslint-disable-next-line no-param-reassign
    details.orderData = orderContext;

    axios
      .post(
        BACKEND_URL_PAY,
        {
          details,
        },
        {
          headers: { 'Access-Control-Allow-Origin': '*' },
        },
      )
      .then((response) => {
        callback(response.data);
      });
  };

  const handleApplePayEvents = (appleSession) => {
    // This is the first event that Apple triggers. Here you need to validate the
    // Apple Pay Session from your Back-End
    // eslint-disable-next-line no-param-reassign, func-names
    appleSession.onvalidatemerchant = function (event) {
      validateApplePaySession(event.validationURL, (merchantSession) => {
        appleSession.completeMerchantValidation(merchantSession);
      });
    };

    // This method is the most important method. It gets triggered after teh user has
    // confirmed the transaction with the Touch ID or Face ID. Besides getting all the
    // details about the customer you also get the Apple Pay payload needed to perform
    // a payment.
    // eslint-disable-next-line no-param-reassign, func-names
    appleSession.onpaymentauthorized = function (event) {
      loading();
      performTransaction(event.payment, (outcome) => {
        if (outcome.approved && !outcome.risk.flagged) {
          try {
            // eslint-disable-next-line no-undef
            appleSession.completePayment(ApplePaySession.STATUS_SUCCESS);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(JSON.stringify(err));
          }
          const amountLeftToPay = String(Number(orderContext.balance) - Number(payAmount));
          paymentData('payment-success', amountLeftToPay);
          Payments(orderContext, payAmount, 'apple-pay');
          loading(false);
        } else if (outcome.approved && outcome.risk && outcome.risk.flagged) {
          try {
            // eslint-disable-next-line no-undef
            appleSession.completePayment(ApplePaySession.STATUS_SUCCESS);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(JSON.stringify(err));
          }
          paymentData('payment-pending', String(Number(orderContext.balance)));
          loading(false);
        } else {
          try {
            // eslint-disable-next-line no-undef
            appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(JSON.stringify(err));
          }
          paymentData('payment-failed', payAmount);
          loading(false);
        }
      });
    };
    // eslint-disable-next-line no-param-reassign, func-names
    appleSession.oncancel = function (event) {
    };
  };

  // eslint-disable-next-line no-undef
  const applePaySession = new ApplePaySession(6, config);
  handleApplePayEvents(applePaySession);
  applePaySession.begin();
}

const getCountryCode = () => {
  const langPrefix = window.location.href.split('/')[3];
  let countryCode;

  switch (langPrefix) {
    case 'de':
      countryCode = 'DE';
      break;

    case 'au':
      countryCode = 'AU';
      break;

    case 'us':
      countryCode = 'US';
      break;

    case 'en-nl':
      countryCode = 'NL';
      break;

    case 'en-ie':
      countryCode = 'IE';
      break;

    default:
      countryCode = 'GB';
  }

  return countryCode;
};

const domainLabel = () => {
  let config = {};
  const domainUrl = window.location.hostname;
  if (domainUrl.match(/americanattractions|aa/)) {
    config = {
      applePayLabel: 'American Attractions',
    };
  } else if (domainUrl.match(/seaworldparks|sw|seaworld/)) {
    config = {
      applePayLabel: 'SeaWorld Parks & Entertainment',
    };
  } else if (domainUrl.match(/universal-bookings|uo|orlando|universal/)) {
    config = {
      applePayLabel: 'Universal Studios',
    };
  } else if (domainUrl.match(/attractiontickets|global|localhost/)) {
    config = {
      applePayLabel: 'Attractiontickets.com',
    };
  }
  return config;
};
