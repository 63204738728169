import { useEffect, useState } from 'react';
import styles from './TravelDocuments.module.scss';
import HotelDetails from './HotelDetails/HotelDetails';
import RoomDetails from './RoomDetails/RoomDetails';
import TransportDetails from './TransportDetails/TransportDetails';
import VoucherDownload from './VoucherDownload/VoucherDownload';
import HotelTickets from './HotelTickets/HotelTickets';

function TravelDocuments(props) {
  const {
    tabID, hotelVouchers, transport, orderStatus, isParisPackage, orderId,
  } = props;
  const [roomSummary, setRoomSummary] = useState(false);
  const [hotelDetails, setHotelDetails] = useState(false);
  const [transportDetails, setTransportDetails] = useState(false);
  const [hotelTickets, setHotelTickets] = useState(false);
  const [voucherDownload, setVoucherDownload] = useState(false);
  useEffect(() => {
    if (hotelVouchers && hotelVouchers[0] && hotelVouchers[0].hotelDetails.rooms && Object.keys(hotelVouchers[0].hotelDetails.rooms).length > 0) {
      setRoomSummary(true);
    }
    if (hotelVouchers && hotelVouchers[0] && hotelVouchers[0].hotelDetails.title && hotelVouchers[0].hotelDetails.title.length > 0) {
      setHotelDetails(true);
      setVoucherDownload(true);
    }
    if (transport && transport.tickets.inbound) {
      setTransportDetails(true);
    }
    if (hotelVouchers && hotelVouchers[0].tickets && Object.keys(hotelVouchers[0].tickets).length > 0) {
      setHotelTickets(true);
    }
    if (hotelVouchers && hotelVouchers[0].tickets && Object.keys(hotelVouchers[0].tickets).length > 0) {
      setHotelTickets(true);
    }
  }, [hotelVouchers]);

  return (
    <div id={tabID} className={styles.travelDocuments}>
      {transportDetails && <TransportDetails transport={transport} />}
      {hotelDetails && <HotelDetails details={hotelVouchers[0].hotelDetails} transport={transport} isParisPackage={isParisPackage} />}
      {roomSummary && <RoomDetails details={hotelVouchers[0].hotelDetails} />}
      {voucherDownload && <VoucherDownload vouchers={hotelVouchers[0].vouchers} bookingCanceled={hotelVouchers[0].bookingCanceled} orderStatus={orderStatus} orderId={orderId} productName={hotelVouchers[0].hotelDetails.title} />}
      {hotelTickets && <HotelTickets tickets={hotelVouchers[0]} orderId={orderId} />}
    </div>
  );
}

export default TravelDocuments;
